import englishMessages from '../i18n/en';
import frenchhMessages from '../i18n/fr';
import dutchMessages from '../i18n/nl';

// fixme: async doesn't work on app init
// const messages = {
//     fr: () => import('../i18n/fr.js').then(messages => messages.default),
//     nl: () => import('../i18n/nl.js').then(messages => messages.default),
// };

export default locale => {
    if (locale === 'fr') {
        // return messages[locale]();
        return frenchhMessages
    }
    if (locale === 'nl') {
        // return messages[locale]();
        return dutchMessages;
    }

    // Always fallback on english
    return englishMessages;
};
