import React, { Context } from 'react';
import { RxStore } from '@ema/rx';
import { ConfigService } from '@ema/core';

class AppState {
    config: any = {};
    ready = false;
    counter = 0;
}

class AppStore extends RxStore<AppState> {

    public config: ConfigService = ConfigService.instance;

    constructor() {
        super(new AppState());
    }

    // public actions

    public async initApp(configUrl: string) {
        await this.config.fetch(configUrl);
        this.setState({...this.state, ...{config: this.config.get(), ready: true}})
    }

    public incrementCounter() {
        this.setState({...this.state, counter: this.state.counter + 1})
    }

}

export const appStore = new AppStore();

export const AppContext = React.createContext(appStore);


