import React, { Component } from 'react';
import { Admin, Resource, ListGuesser } from 'react-admin'; // eslint-disable-line import/no-unresolved
import { Route } from 'react-router';
import { reducer as tree } from 'ra-tree-ui-materialui';

import {appStore} from './app-store';
import authProvider from './common/authProvider';
import comments from './pages/comments';
import CustomRouteLayout from './pages/customRouteLayout';
import CustomRouteNoLayout from './pages/customRouteNoLayout';
import dataProvider from './common/dataProvider';
import i18nProvider from './common/i18nProvider';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import posts from './pages/posts';
import users from './pages/users';
import tags from './pages/tags';
// import {customTheme} from './theme';

export default class App extends Component {

    state: any = {};

    constructor(props) {
        super(props);
    }

    componentDidMount(): void {
        appStore.state$.subscribe((appState: any) => {
           this.setState({...this.state, ...appState})
        });
        appStore.initApp('config.json');
    }

    render() {
        if (this.state.ready) {
            console.log('app render');
            return (
                <Admin
                    authProvider={authProvider}
                    dataProvider={dataProvider}
                    dashboard={Dashboard}
                    i18nProvider={i18nProvider}
                    title={this.state.config.appName}
                    // theme={customTheme}
                    locale={this.state.config.language}
                    loginPage={Login}
                    customReducers={{tree}}
                    customRoutes={[
                        <Route
                            exact
                            path="/custom"
                            component={CustomRouteNoLayout}
                            noLayout
                        />,
                        <Route exact path="/custom2" component={CustomRouteLayout}/>,
                    ]}
                >
                    {permissions => [
                        <Resource name="posts" {...posts} />,
                        <Resource name="comments" {...comments} />,
                        permissions ? <Resource name="users" {...users} /> : null,
                        <Resource name="tags" {...tags} />,
                    ]}
                </Admin>
            );
        } else {
            return null;
        }
    }

}
