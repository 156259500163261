import React, { useContext, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {AppContext} from '../app-store';

export default () => {

    const appStore = useContext(AppContext);
    const [title, setTitle] = useState('');

    useEffect(() => {
        let subscription = appStore.state$.subscribe((appState) => {
            setTitle(appState.config.appName);
        });
        // called on unmount
        return () => {
            subscription.unsubscribe();
        };
    });

    return (
         <Card>
            <CardHeader title={title} />
            <CardContent>
                Dashboard..
                <hr/>
                <button className={'btn btn-primary'} onClick={ () => appStore.incrementCounter() }>+ counter</button>
                <hr/>
                <h3>{appStore.state.counter}</h3>
            </CardContent>
        </Card>
    );
}
