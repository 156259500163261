import React from 'react';
import { Login as LoginDefault, LoginForm } from 'ra-ui-materialui';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    login: {
        main: {
            backgroundColor: '#eeeeee',
        },
        card: {
            padding: '5px 0 5px 0',
        },
    },
    form: {
        button: {
            height: '3em',
        }
    },
};

const CustomLoginForm = withStyles(styles.form)(LoginForm);

const Login = () => (
    <LoginDefault
        backgroundImage={'none'}
        style={styles.login.main}
        loginForm={<CustomLoginForm />}
    />
);

export default withStyles(styles.login)(Login);
